<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Due List</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="merchant_id">Merchant</label>
                                            <v-select name="merchant_id"
                                                v-model="search.merchant_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= merchantList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="date_parameter_id">Date Parameter</label>
                                            <select id="date_parameter_id" v-model="search.date_parameter_id" class="form-control">                                            
                                                <option v-for="(item, index) in dateParameterList" :value="item.id" :key="index">{{ item.text }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="start_date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="end_date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm mr-2" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                            <button class="btn btn-success btn-sm mr-2" @click.prevent="codCalculate">COD Calculate</button>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                         <div class="form-group">
                                            <label>Total Due: </label>
                                            <input type="text" v-model="totalDue" class="form-control" readonly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total SLA Missed</th>
                                                    <th>Total SLA Missed(ISD)</th>
                                                    <th>Total SLA Missed(OSD)</th>
                                                    <th>Total SLA Missed(SA)</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold curson-pointer">
                                                    <td @click="searchSlaMissedOrder(0)">{{ summary ? summary.total_sla_missed : 0 }}</td>
                                                    <td @click="searchSlaMissedOrder(1)">{{ summary ? summary.total_sla_missed_isd : 0 }}</td>
                                                    <td @click="searchSlaMissedOrder(2)">{{ summary ? summary.total_sla_missed_osd : 0 }}</td>
                                                    <td @click="searchSlaMissedOrder(3)">{{ summary ? summary.total_sla_missed_sa : 0 }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="collectionTable" class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Merchant</th>
                                                    <th>Total Order</th>
                                                    <th>Amount</th>
                                                    <th style="text-align:center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order, key) in listData" :key="key">
                                                    <td>{{ order.merchant_business }}</td>
                                                    <td>{{ order.total_order }}</td>
                                                    <td>{{ order.amount }}</td>
                                                    <td style="text-align:center">
                                                        <button class="btn btn-success btn-sm" @click="makePayment(order.merchant_id, search.date_parameter_id, search.start_date, search.end_date)" title="Make Payment"> Make Payment</button>    
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'Due',
        data(){
            return {   
                loader: false,             
                orders:[],
                totalDue: 0,
                search: {
                    merchant_id: '',
                    amount: '',
                    date_parameter_id: 1,
                    start_date: moment().subtract(90,'d').format('YYYY-MM-DD'),
                    end_date: ''
                },
                form:{
                    merchant_id : '',
                    orderIds: [],
                    account_type: 5,
                    payable: 0
                },
                errors:[],
                deleteModal:false,
                order_id:'',
                key:'',
                allSelected: false,
                summary: null,
                date: '',
                dateParameterList: [
                    { id: 1, text: 'Deadline' },
                    { id: 2, text: 'Activity Date' }
                ],
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            if (this.$route.query) {
                Object.assign(this.search, this.$route.query)
            } 
            this.loadData();
            this.loadTotalDue();
            this.loadSlaSummary()
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                    this.loadSlaSummary()
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            merchantList: function () {
                return this.$store.state.commonObj.merchantList
            }
        },
        methods:{
            reload () {
                Object.assign(this.search, {
                    merchant_id: '',
                    amount: '',
                    start_date: moment().subtract(90,'d').format('YYYY-MM-DD'),
                    end_date: ''
                })
            },
            selectAll () {
                this.form.orderIds = [];
                if (!this.allSelected) {
                    const orders = this.orders
                    for (var order in orders) {
                        this.form.orderIds.push(orders[order].id);
                    }
                } 
            },
            select(orderId) {
                this.form.orderIds.push(orderId)
                this.allSelected = false;
            },
            makePayment (merchant_id, date_parameter_id, start_date, end_date) {
                this.$router.push('make-payment?merchant_id='+ merchant_id + '&date_parameter_id='+ date_parameter_id + '&start_date='+ start_date + '&end_date='+ end_date)
            },         
            searchSlaMissedOrder (coverage_area_id) {
                var startDate = ''
                if (coverage_area_id == 1) {
                    startDate = this.summary.sla_isd_date
                } else if (coverage_area_id == 2) {
                    startDate = this.summary.sla_osd_date
                } else if (coverage_area_id == 3) {
                    startDate = this.summary.sla_sa_date
                }
                this.search.start_date = startDate
                this.loadData()
                this.loadSlaSummary()
            },
            searchData () {
                this.loadData()
                this.loadSlaSummary()
            },
            async loadData(){     
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
                const response = await config.getData('/accounts/payment/due', params)
                this.loader = false
                if (response.status == 200){
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            async loadTotalDue(){    
                const response = await config.getData('/accounts/payment/due-amount', this.search)
                if (response.status == 200){
                    this.totalDue = response.total_due
                } else {
                    this.totalDue = 0
                } 
            },
            async codCalculate(){    
                this.loader = true      
                const response = await config.getData('/accounts/payment/cod-calculate', this.search)
                this.loader = false
                if (response.success){
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                } 
            },
            async loadSlaSummary(){    
                this.loader = true 
                const params = { merchant_id: this.search.merchant_id }      
                const response = await config.getData('/accounts/payment/sla-summary', params)
                this.loader = false
                if (response.status == 200){
                    this.summary = response.summary
                } else {
                    this.summary = null
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            getRelationalData(data) {
                const merchantList = this.$store.state.commonObj.merchantList
                const listData = data.map(item => {
                    const tmpData = merchantList.find(merchant => merchant.id === item.merchant_id)
                    const merchantData = { merchant_business: tmpData !== undefined ? tmpData.text : '' }
                    return Object.assign({}, item, merchantData)
                })
                return listData
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>